.root {
    width: 45px;
    height: 45px;
    padding: 11px;
}

.root:last-child {
    margin-right: 0;
}

.svg {
    display: block;
    transform: translate(0, -2px);
}

.journee-accueil-table {
    width: 100%;
    border-collapse: collapse;
}

.journee-accueil-table td {
    border: solid 1px black;
    padding: 1rem;
}

.journee-accueil-table th {
    border: solid 1px black;
    padding: 1rem;
}

.journee-accueil-table-title {
    font-weight: bold;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-bottom: 4rem;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

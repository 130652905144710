.root {
    color: #222;
    line-height: 28px;
}

.root h1 {
    font-size: 32px;
    font-weight: 500;
    margin: 0 0 64px;
}

.root h2 {
    font-weight: 500;
    margin-top: 48px;
    line-height: 48px;
}

.root h3 {
    font-weight: 500;
    margin-top: 48px;
}

.nlb {
    white-space: nowrap;
}

.root {
    margin-top: 68px;
}

.list {
    list-style: none;
    padding: 0;
}

.section {
    margin-bottom: 48px;
    margin-right: 16px;
}

.section:last-child {
    margin-bottom: 0;
}

.title {
    color: #c4c4c4;
    display: block;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 16px;
}

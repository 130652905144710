.root {
    font-size: 14px;
    margin: 8px 0;
    display: flex;
    padding: 4px 0;
    flex-direction: row;
    align-items: center;
}

.link {
    text-decoration: none;
    color: #222;
    flex: 1;
}

.iconWrapper {
    padding-right: 3px;
    padding-left: 3px;
    display: flex;
    font-size: 16px;
    width: 23px;
}

.active {
    background: #f1f1f1;
}

.active .link {
}

.done {
    color: #c1c1c1;
}

.disabled {
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
}

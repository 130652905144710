.intro {
    font-size: 21px;
    line-height: 32px;
    text-align: center;
}

.title {
    margin: 0 !important;
}

.signature {
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.signature p {
    margin-right: 200px;
    margin-bottom: 64px;
}

.root {
    border: solid 1px #ddd;
    padding: 24px;
    margin-top: 48px;
    margin-bottom: 64px;
    font-size: 16px !important;
}

.account {
    border: solid 1px orange;
    padding: 24px;
    margin-top: 48px;
    margin-bottom: 64px;
    font-size: 16px !important;
}

.title {
    margin: 32px 0 0 0;
    color: #c4c4c4;
    text-transform: uppercase;
    font-size: 12px;
}

.titleNoMargin {
    margin: 0;
}

.label span {
    font-size: 16px !important;
}

.info {
    padding-left: 36px;
    font-size: 0.8rem;
}

.info p {
    margin: 0;
}

.noPointer {
    cursor: default !important;
}

.container {
    margin-bottom: 64px;
}

.debug {
    background: #444;
    padding: 12px 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.debug a {
    text-decoration: none;
}

h4 {
    margin-top: 3rem;
}
